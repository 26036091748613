import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

const Table = ({ columns, dataTable }) => {

  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([]);
  
  useEffect(() => {
    setRowData(dataTable);
    setColumnDefs(columns);
  });
  
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true
  }));

  const cellClickedListener = useCallback((event) => {
    //console.log(event);
  }, []);

  return (
    <div className="ag-theme-alpine" style={{ width: "98%", height: 800 }}>
      <AgGridReact
        ref={gridRef} 
        rowData={rowData} 
        columnDefs={columnDefs} 
        defaultColDef={defaultColDef} 
        animateRows={true} 
        rowSelection="multiple" 
        onCellClicked={cellClickedListener} 
        paginationAutoPageSize={true}
        pagination={true}
        cacheQuickFilter={true}
      />
    </div>
  );
};

export default Table;
