import React, { useEffect, useContext, useState } from "react";
import {
  reportPageIcon,
  reportPageIconColored,
  homeIcon,
} from "../assets/images";
import PageLayout from "../Layouts/PageLayout";
import ContextApp from "../contexts/ContextApp";
import "jspdf-autotable";
import { Button } from "react-bootstrap";
import { Table } from "../components";
import { CSVLink } from "react-csv";
import { ReportService } from "../services";
import Select, { createFilter } from "react-select";
import Loader from "../components/Loader"
import moment from "moment";

import "../styles/Report.css";



const ReportPage = () => {
  const { setCrumbs, moodle } = useContext(ContextApp);

  const [periodList, setPeriodList] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [data, setData] = useState([]);

  const [loading, setLoanding] = useState(false);

  const [columns, setColumns] = useState([]);

  const headers = [
    { field: "grupo", headerName: "GRUPO" },
    { field: "oferta", headerName: "OFERTA" },
    { field: "cpf", headerName: "CPF" },
    { field: "nome", headerName: "NOME" },
    { field: "email", headerName: "E-MAIL" },
    {
      field: selectedOptions.length === 1 ? "situacao" : "situacaoAtual",
      headerName: selectedOptions.length === 1 ? "SITUAÇÃO" : "SITUAÇÃO ATUAL",
    },
    { field: "data_entrada", headerName: "DATA ENTRADA" },
    { field: "migrado", headerName: "MIGRADO" },
    {
      field: "data_migrado",
      headerName: "DATA MIGRADO",
      valueFormatter: (params) => {
        return (params.data.data_migrado !== '' && params.data.data_migrado !== 0) ? params.data.data_migrado : "-";
      }
    },
    {
      field: "satisfatorioCHMenor30",
      headerName: "SATISFATÓRIO ABONO 30"
    },
    {
      field: "duplicado",
      headerName: "DUPLICADO"
    },
  ];

  const maxPeriods = 3

  const periodIds = [];
  
  const [periods, setPeriods] = useState([]);

  const [headersFile, setHeadersFile] = useState([]);

  useEffect(() => {
    setHeadersFile(
      columns.map((cur) => {
        return { key: cur.field, label: cur.headerName.toUpperCase() };
      })
    );
  }, [columns]);

  useEffect(() => {
    if (periodList.length)
      setPeriods(
        periodList.map((cur) => {
          return { 
            value: cur.period, 
            label: "Período: " + cur.period + " - " + moment(cur.data_inicio).format("DD/MM/YYYY") + " até " + moment(cur.data_fim).format("DD/MM/YYYY")
          };
        })
      );
  }, [periodList]);

  useEffect(() => {
    let newCrumbs = [
      { name: "Home", path: "home", icon: homeIcon },
      { name: "Relatórios", path: "reports", icon: reportPageIconColored },
    ];
    setCrumbs(newCrumbs);
    return () => {
      let newCrumbs = [
        { name: "Home", path: "home", icon: homeIcon }
      ];
      setCrumbs(newCrumbs);
    };
  }, []);

  useEffect(() => {
    setPeriodList([]);
    ReportService.getPeriodList(moodle).then((response) => {
      //console.log(response.data)
      setPeriodList(response.data);
    });
  }, []);

  function handleSelect(options) {
    setSelectedOptions(options);
    if (!options.length !== 0) {
      setData([]);
    }
  }

  const getData = () => {
    periodIds.current = selectedOptions.map((elm) => elm.value);

    if (periodIds.current.length > 0 && periodIds.current.length <= maxPeriods) {
      //console.log(periodIds.current, periodIds.current.toString())
      setLoanding(true);

      
      ReportService.getPeriodDetailsByIds(moodle, periodIds.current.toString())
        .then((response) => {
          const columnsPeriods = []
          
          periodIds.current.forEach(period => {
            columnsPeriods.push(
              {
                headerName: periodIds.current.length > 1 ? "DESEMPENHO: CICLO  " + period : "DESEMPENHO",
                field: "Ciclo" + period,
                filter: true
              }
            );
          });

          
          if (periodIds.current.length === 1){
            setColumns([
              ...headers,
              {
                headerName: "MATRÍCULAS",
                field: "matriculas",
                valueFormatter: (params) => {
                  return params.value ? params.value : 0;
                }
              },
              {
                headerName: "CURSOS CONCLUÍDOS",
                field: "cursos_concluidos",
                valueFormatter: (params) => {
                  return params.value ? params.value : 0;
                }
              },
              {
                headerName: "CH",
                field: "ch",
                valueFormatter: (params) => {
                  return params.value ? params.value : 0;
                }
              },
              {
                headerName: "ACESSO",
                field: "acesso"
              },
              ...columnsPeriods
            ]);
          }else{
            setColumns([
              ...headers,
              ...columnsPeriods
            ]);
          }
          
          
          return response;
        })
        .then(function(details) {
          /*
          details.data = details.data.map((elm) => {
            elm.desempenhos.forEach((curr) => {
              elm["periodo" + curr.periodo] = curr.desempenho;  
              elm["acesso"] = curr.acesso;
              elm["ch"] = curr.ch              
            });
            delete elm.desempenhos;
            return elm;
          });*/
          
          setData(details.data);
          setLoanding(false);
        });
      
    }
    if (selectedOptions.length === 0) setData([]);
  };
  
  return (
    <PageLayout pageIcon={reportPageIcon} pageTitle="Relatórios">
      <div className="ReportContainer">
        <div className="SideMenu">
          <Select
            options={periods}
            placeholder={"PERÍODOS"}
            value={selectedOptions}
            hideSelectedOptions={false}
            onChange={handleSelect}
            isSearchable={true}
            isMulti
            isOptionDisabled={() => selectedOptions.length === maxPeriods}
            filterOption={createFilter({ ignoreAccents: false })}
            // closeMenuOnSelect={false}
            // blurInputOnSelect={false}
            // styles={customStyles}
            // className="basic-multi-select"
            // classNamePrefix="select"
            className="drop-periods"
          />
          <Button
            disabled={selectedOptions.length > 0 ? false : true}
            className="btn-generate"
            onClick={getData}
          >
            GERAR RELATÓRIO
          </Button>
          <Button disabled={data.length === 0 ? true : false} className="btn-download">
            <CSVLink
              data={data}
              headers={headersFile}
              filename={"Relatório de Desempenho - " + selectedOptions.map((elm) => elm.value).toString() + ".xlsx"}
              style={{ textDecoration: "none", color: "white" }}
            >
              DOWNLOAD
            </CSVLink>
          </Button>
        </div>
        <div className="MainContent">
          {data.length > 0 && loading === false ? (
              <Table columns={columns} dataTable={data} />
          ) : loading === true ? <Loader/> : "Sem dados para exibição."}
        </div>
      </div>
    </PageLayout>
  );
};

export default ReportPage;
