import '../styles/Cadastro.css'
import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineClose } from 'react-icons/ai';
import { Button } from "react-bootstrap";

const baseURL = window._env_.REACT_APP_BACKEND_URL ;

const Cadastro = ({ isOpen, setModal, isCad, user, getUsers, setUsers} ) => {

    const [username, setUsername] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [isAdmin, setIsAdmin] = useState(0);

    //limpar dados do form
    const limparDados = () => {
        setUsername("");
        setName("");
        setEmail("");
        setPhone("");
        setCity("");
        setCountry("");
        setIsAdmin("");
    }

    //preencher o form com as infos do usuario para edicao
    useEffect(() => {
        if (!isCad && user) {
          setUsername(user.username);
          setName(user.name);
          setEmail(user.email);
          setPhone(user.phone);
          setCity(user.city);
          setCountry(user.country);
          setIsAdmin(user.admin);
        }
      }, [isCad, user]);


    //funcao para editar usuario
    const handleEdit = async () => {
        const id = user.id;
        try {
            await axios.post(baseURL + "/user/update", {
              id,
              name,
              email,
              username,
              phone,
              city,
              country,
              admin: isAdmin,
            })
            .then((response) => {
                Promise.resolve(response)
                if (response.data.status) {
                    alert("Usuário atualizado com sucesso");
                    setModal();
                    limparDados();
                    getUsers();
                } else {
                alert("Erro ao atualizar usuário");
                }
            });
          } catch (error) {
            alert("Erro ao tentar atualizar o cadastro:", error);
          }
        };

    
    //funcao para cadastrar usuario
    const handleCadastro = async () => {
      try {
        await axios.post(baseURL + "/user/create", {
          name,
          email,
          username,
          phone,
          password: 123456,
          city,
          country,
          admin: isAdmin,
          suspended: 0
        })
        .then((response) => {
            Promise.resolve(response)
            if (response.data.status) {
                alert("Usuário cadastrado com sucesso");
                setModal();
                limparDados();
                getUsers();
            } else {
                alert("Erro ao cadastrar usuário");
            }
        });
      } catch (error) {
        alert("Erro ao fazer o cadastro:", error);
      }
    };
    

    if(isOpen) {
        return (
        <div id="background">
            <div id="pagecad" className="box"> 
                {isCad && <h1 id="h1cadastro">CADASTRAR</h1>}
                {!isCad && <h1 id="h1editar">EDITAR</h1>}

                <button 
                type="submit" 
                id="buttonfechar" 
                onClick={() => {setModal(); limparDados();}}>
                <span><AiOutlineClose/></span>
                </button>

                <input
                className='formCadastro'
                type="text"
                id="nomecad"
                placeholder="NOME COMPLETO"
                value={name}
                onChange={(e) => setName(e.target.value)}
                />
                <input
                className='formCadastro'
                type="email"
                id="emailcad"
                placeholder="EMAIL"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
                <input
                className='formCadastro'
                type="number"
                id="cpfcad"
                placeholder="CPF"
                maxLength={11}
                value={username}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,11}$/.test(value)) {
                      setUsername(value);
                  }
                }}
                />
                <input
                className='formCadastro'
                type="phone"
                id="phonecad"
                placeholder="TELEFONE"
                maxLength={11}
                value={phone}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,11}$/.test(value)) {
                      setPhone(value);
                  }
                }}
                />
                <input
                className='formCadastro'
                type="text"
                id="citycad"
                placeholder="CIDADE"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                />
                <input
                className='formCadastro'
                type="text"
                id="countrycad"
                placeholder="PAÍS (SIGLA)"
                maxLength={2}
                value={country}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^[A-Za-z]{0,2}$/.test(value)) {
                      setCountry(value.toUpperCase());
                  }
              }}
                />
        
                <input
                type="checkbox"
                id="adminput"
                checked={isAdmin === 0}
                onChange={(e) => setIsAdmin(e.target.checked ? 0 : 1)}
                />
                <label
                htmlFor="adminput"
                id="admlabel"
                className={isAdmin === 0 ? "checked" : ""}
                >
                ADMINISTRADOR
                </label>               

                {isCad && <Button className="btn-cadastro" onClick={handleCadastro}>
                    CADASTRAR
                </Button>}

                {!isCad && <Button className="btn-cadastro" onClick={handleEdit}>
                    SALVAR
                </Button>}

            </div>
        </div>
        );
        };
    
    return null;
};

export default Cadastro