import styled, { css } from 'styled-components';

export const theme1 = {
  primary: '#00c29a',
  secondary: '#2D658C',
  secon: '#1A4863',
  danger: '#FF3643',
  light: '#f4f4f4',
  dark: '#222',
  gray: '#A9A9A9'
};

export const theme2 = {
  primary: '#6e27c5',
  secondary: '#ffb617',
  danger: '#f16623',
  light: '#f4f4f4',
  dark: '#222'
};

export const Button = styled.button`
  font-size: 1rem;
  border-radius: 5px;
  padding: 0.25rem 1rem;
  margin: 0 1rem;
  background: transparent;
  color: ${props => props.theme.primary};
  border: 2px solid ${props => props.theme.primary};
  cursor: pointer;
  ${props =>
    props.primary &&
    css`
      background: ${props => props.theme.primary};
      color: #FFFFFF;
    `};
  &:hover {
    transform: translateY(1px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  }
  &:disabled {
    background: ${props => props.theme.gray};
    transform: translateY(1px);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  }  
`;
