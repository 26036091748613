import React from "react";
import styled from "styled-components";
import { Breadcrumbs } from "../components";

import "../styles/PageLayout.css";

const ContainerContent = styled.div`
  display: flex !important;
  flex-flow: row nowrap;
  gap: 0.5rem;
  justify-content: center;
  min-height: 10rem;
`;

const MiniHeaderS = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 6rem;
  position: relative;
  top: 0;
  margin-bottom: 1rem;

  .headerImg {
    width: 16%;
    background-color: #00c29a;
    padding: 0.3rem;

    img {
      width: 5rem;
    }
  }

  .headerText {
    width: 94%;
    font-size: 2rem;
    color: #FFFFFF;
    font-weight: 600;
    display: flex;
    justify-content: initial;
    vertical-align: bottom;
    align-items: center;
    padding: 0.8rem;
    background-color: #2d658c
  }
`;



const MiniHeader = (props) => {
  return (
    <MiniHeaderS>
      <div className="headerImg">
        <img src={props.pageIcon} alt="icone da pagina"></img>
      </div>
      <div className="headerText">
        <span>{props.pageTitle}</span>
      </div>
    </MiniHeaderS>
  );
};

const PageLayout = (props, { children }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          // width: "80vw",
          flexFlow: "row nowrap",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "5rem",
          maxWidth:"85%",
          flex: "1 1 85%",
        }}
      >

      </div>
      <div className="container-history" style={{display:"flex", flexFlow:"wrap"}} >
        <div style={{display:"flex", flexFlow:"row nowrap", justifyContent:"space-between", minWidth:"85%",}} >
        <Breadcrumbs crumbs={"home"} />
        </div>
        <div
          className="layout-body"
          style={{
            padding: "0",
            display: "flex",
            gap: "0.5",
            flexDirection: "column",
            flexWrap: "nowrap",
          }}
        >
          <MiniHeader
            pageIcon={props.pageIcon}
            pageTitle={props.pageTitle}
          ></MiniHeader>
          <ContainerContent>
            <main style={{width:"100%", margin:"0.5rem"}}>{props.children}</main>
          </ContainerContent>
        </div>
      </div>
    </>
  );
};

export default PageLayout;
