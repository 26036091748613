import React, { useState } from "react";
import ImgUser from "../assets/images/user.png";
import { useEffect, useContext } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import "../styles/Header.css";
import { AuthService } from "../services";
import { useNavigate } from "react-router-dom";
import ContextApp from "../contexts/ContextApp";


const Header = () => {

  const { moodle, setMoodle } = useContext(ContextApp);
  const navigation = useNavigate();
  const [name, setName] = useState("Usuário");
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(async function() {
    const user = await AuthService.getCurrentUser();
    if (user) {
      setName(user.name);
      setShowDropdown(true);
    }
    if (!user){
      setName("Login");
      setShowDropdown(false);
    } 
  });

  const selectingMoodle = (e) => {
    setMoodle(e.target.value);
    if (e.target.value === "moodleA"){
      document.getElementById('body').style.backgroundColor = "#194863"
    }else{
      document.getElementById('body').style.backgroundColor = "#223c4b"
    }
    navigation("/home");
  };


  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        {showDropdown === true && (
          <Dropdown>
            <Dropdown.Toggle
              type="button"
              className="btn-ambiente"
              variant="btn-ambiente"
            >
              Ambiente {moodle === "moodleA" ? "A" : "B"}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: "100%", padding: "0.5rem"}}>              
              <Form>
                <Form.Check
                  checked={moodle === "moodleA"}
                  type="radio"
                  id="moodleASwitch"
                  label="Ambiente A"
                  value={"moodleA"}
                  onChange={selectingMoodle}
                />
                <Form.Check
                  checked={moodle === "moodleB"}
                  id="moodleBSwitch"
                  type="radio"
                  label="Ambiente B"
                  value={"moodleB"}
                  onChange={selectingMoodle}
                />
              </Form>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {showDropdown === false && (
          <Button
            type="button"
            className="btn-ambiente"
            variant="btn-ambiente"
          >
            Ambiente {moodle === "moodleA" ? "A" : "B"}
          </Button>
        )}


        {showDropdown === true && (
          <Dropdown className = "dropProfile">
            <Dropdown.Toggle
              type="button"
              className="btn-profile"
              variant="btn-profile"
            >
              <img src={ImgUser} alt="Avatar" className="img-profile" />
              {name}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ width: "100%", padding: "0.5rem" }}>
              <Dropdown.Item
                href="#"
                onClick={() => {
                  navigation("/profile");
                }}
              >
                Perfil
              </Dropdown.Item>
              <Dropdown.Item
                href="#"
                onClick={() => {
                  AuthService.logout();
                  navigation("/");
                }}
              >
                Sair
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {showDropdown === false && (
          <Button
            type="button"
            className="btn-profile"
            variant="btn-profile"
          >
            {name}
          </Button>
        )}        
      </nav>
    </>
  );
};

export default Header;
