import React from "react";
import { SearchPage, LoginPage, HomePage, ReportPage, UserPage, ProfilePage } from "./pages";
import { ProtectedRoute } from "./components/ProtectedRoute";


export default [
  { path: "/", name: "Login", element: <LoginPage /> },
  {
    path: "/home",
    name: "Home",
    element: (
      <ProtectedRoute>
        <HomePage />
      </ProtectedRoute>
    ),
  },
  {
    path: "/Search",
    name: "Busca",
    element: (
      <ProtectedRoute>
        <SearchPage  />
      </ProtectedRoute>
    ),
  },
  {
    path: "/Reports",
    name: "Relatórios",
    element: (
      <ProtectedRoute>
        <ReportPage />
      </ProtectedRoute>
    ),
  },
    {
      path: "/users",
      name: "Users",
      element: (
        <ProtectedRoute admin={true}>
          <UserPage />
        </ProtectedRoute> 
      ),
  },
  {
    path: "/profile",
    name: "Profile",
    element: (
      <ProtectedRoute>
        <ProfilePage />
      </ProtectedRoute> 
    ),
  }, 
];
