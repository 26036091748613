import React, { useContext } from "react";
import ContextApp from "../contexts/ContextApp";
import { useEffect, useState } from "react";
import PageLayout from "../Layouts/PageLayout";
import {
  userPageIcon,
} from "../assets/images";
import { Table, Cadastro } from "../components";
import { FaEdit } from "react-icons/fa";
import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import { userIcon, homeIcon } from "../assets/images";
import { Button } from "react-bootstrap";
import "../styles/UserPage.css"

const baseURL = window._env_.REACT_APP_BACKEND_URL ;


function Users() {

  const axios = require('axios');
  const { setCrumbs } = useContext(ContextApp);
  const [users, setUsers] = useState([]);
  const [busca, setBusca] = useState("");


  useEffect(() => {
    let newCrumbs = [
      { name: "Home", path: "home", icon: homeIcon },
      { name: "User", path: "users", icon: userIcon }
    ];
    setCrumbs(newCrumbs);
  }, []);


  //construcao da tabela de usuarios
  const getUsers = async () => {
    try {
      const res = await axios.get(baseURL + '/user/listar');
      setUsers(res.data.sort((a, b) => (a.nome > b.nome ? 1 : -1)));
    } catch (error) {
      console.error("Erro ao obter os usuários:", error);
    }
  };

  //aualizar tabela de usuarios
  useEffect(() => {
    getUsers();
  }, [setUsers]);


  //Configurando corpo da tabela
  const columns = [
    { field: "name", headerName: "NOME" },
    { field: "email", headerName: "E-MAIL" },
    { field: "phone", headerName: "TELEFONE" },
    { field: "username", headerName: "CPF"},
    {
      field: "admin",
      headerName: "ADMINISTRADOR",
      valueGetter: (params) => (params.data.admin === 0 ? "Sim" : "Não"),
      width: 150,
    },
    {
      field: "suspended",
      headerName: "SUSPENSO",
      valueGetter: (params) => (params.data.suspended === 1 ? "Sim" : "Não"),
      width: 120,
    },
    {
      headerName: "Ações",
      field: "actions",
      cellRendererFramework: (params) => (
        <div className="actions-container">
          <button
            className="bt-table"
            onClick={() => handleEdit(params.data)}
            title="Editar"
          >
            <FaEdit />
          </button>
          <button
            className="bt-table"
            onClick={() => handleSuspended(params)}
            title={params.data.suspended === 0 ? "Desativar" : "Ativar"}
          >
            {params.data.suspended === 0 ? <AiOutlineClose /> : <AiOutlineCheck />}
          </button>
        </div>
      ),
      width: 150,
    },
  ];

  //Filro de usuario
  const usuarioFiltrado = users.filter((user) => {
    const lowerCase = busca.toLowerCase();
    return user.name.toLowerCase().includes(lowerCase);
  })

  //variaveis para abrir modal
  const [openModal, setOpenModal] = useState(false)
  const [isCad, setCad] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null);


  //Abrir modal quando for edicao
  const handleEdit = (user) => {
    setSelectedUser(user);
    setOpenModal(true);
    setCad(false);
  };


  //funcao para ativar/desativar usuario 
  const handleSuspended = async (params) => {
    
    const id = params.data.id;
    const suspended = params.data.suspended;
    let ativo;

    if(suspended === 1) {
      ativo = 0;
    } else if (suspended === 0) {
      ativo = 1;
    };
    //console.log(ativo);
    
    try {
        await axios.post(baseURL + "/user/suspended", {
          id,
          suspended: ativo
        })
        .then((response) => {
            Promise.resolve(response)
            if (response.data.status && ativo === 1) {
                alert("Usuário desativado com sucesso");
                getUsers();
            }else if (response.data.status && ativo === 0) {
              alert("Usuário ativado com sucesso");
              getUsers();
            } else {
                alert("Erro ao assss usuário");
              }
        });
      } catch (error) {
          alert("Erro ao tentar atualizar o cadastro:", error);
      }
  };


  return (
    <PageLayout pageIcon={userPageIcon} pageTitle="Usuários">
        <div className="UserContainer">
            <div className="searchUser">
                <input
                type="text"
                placeholder="Buscar usuário"
                name="user"
                className="form-control searchInput"
                value={busca}
                onChange={(e) => setBusca(e.target.value)}
                />
                <Button className="btn-generate"  onClick={() => { 
                    setOpenModal(true);
                    setCad(true)}}>
                    ADICIONAR
                </Button>
            </div>
            <div className="tableUsers">
                <Table id="tableUsers" columns={columns} dataTable={usuarioFiltrado} />
            </div>
            <Cadastro isOpen={openModal} setModal={() => setOpenModal(!openModal)} isCad={isCad} user={selectedUser} getUsers={() => getUsers()} setUsers={() => setUsers()}/>
        </div>
      
    </PageLayout>
  );
}

export default Users;
