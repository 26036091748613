import styled from "styled-components";

const FooterContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  /* height: 15%; */
  height: 15rem;
  background-color: #1A4863;
`;

const SocialMediaIcons = styled.div`
  /* min-width: 100vw; */
  display: flex;
  justify-content: end;
  flex-flow: row wrap;
  gap: 1.8rem;
  width: 100%;
  img{
      width: 2.3rem;
  }
  margin-right: 8%;
`;
const Line = styled.div`
  height: 0.63rem;
  width: 25%;
  background-color: ${(props) => props.color || "red"};
  margin-top: 0%;
`;

export { FooterContainer, Line, SocialMediaIcons };
