import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import ContextApp from "../src/contexts/ContextApp";
import routes from "./routes";
import Layout from "./Layouts/MainLayout";
import { ThemeProvider } from "styled-components";
import { theme1  } from "./theme/globalStyle";
import { NotFoundPage } from "./pages";


const App = () => {
  const [moodle, setMoodle] = useState("moodleA");
  const [crumbs, setCrumbs] = useState([{name:"Home", path:"Home", ico: "homeIconColored"}])

  return (
    <ContextApp.Provider value={{ moodle, setMoodle, crumbs, setCrumbs }}>
          <Routes>
            <Route path="*" element={<NotFoundPage />} />
            {routes.map(({ path, name, element }, key) => {
              return (
                <Route key={key} path={path} name={name} element={<Layout className="App">{element}</Layout>} />
              );
            })}
          </Routes>
    </ContextApp.Provider>
  );
};

const AppWrapper = () => {
  return (
<ThemeProvider theme={theme1}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
</ThemeProvider>

  );
};

export default AppWrapper;
