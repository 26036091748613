import React, { useState, useEffect, useContext } from "react";
import { StudentService } from "../services";
//import styled from "styled-components";
import { Link } from "react-router-dom";
import { Table, ButtonGroup, Button } from "react-bootstrap";
import moment from "moment";
import ContextApp from "../contexts/ContextApp";

import PageLayout from "../Layouts/PageLayout";
import Loader from "../components/Loader"

import "../styles/Search.css";

import { searchPageIcon, searchIcon, searchPageIconColored, homeIcon} from "../assets/images";


const StudentProfile = (props) => {
  const profile = props.profile;
  return (
    <div className="Profile">
      <h5>
        <b>Detalhes do Usuário:</b>
      </h5>
      <label>
        <b>Nome Completo</b>
      </label>
      <span>{profile.firstname + " " + profile.lastname}</span>
      <label>
        <b>E-mail</b>
      </label>
      <span>{profile.email}</span>
      <label>
        <b>País</b>
      </label>
      <span>{profile.country || "-"}</span>
      <label>
        <b>Cidade/Município</b>
      </label>
      <span>{profile.city || "-"}</span>
      <label>
        <b>Grupo de Entrada</b>
      </label>
      <span>{profile.codoferta || "-"}</span>
      <label>
        <b>Id da Oferta</b>
      </label>
      <span>{profile.idoferta || "-"}</span>
      <label>
        <b>Data de Entrada</b>
      </label>
      <span>{profile.dataentrada || "-"}</span>
      <label>
        <b>Data de Migração</b>
      </label>
      <span>{(profile.dataMigrado && profile.dataMigrado !=='0') ? 
              moment(profile.dataMigrado, "YYYY-MM-DD").format("DD/MM/YYYY").toString()
              : "-"}
      </span>
      <label>
        <b>Status Atual</b>
      </label>
      <span>{profile.suspended === 0 ? "Ativo" : "Inativo"}</span>
      <label>
        <b>Primeiro Acesso</b>
      </label>
      <span>{profile.firstaccess || "-"}</span>
      <label>
        <b>Último Acesso</b>
      </label>
      <span>{profile.lastaccess || "-"}</span>
    </div>
  );
};

const SearchComponent = () => {
  const [historic, setHistoric] = useState([]);
  const [profile, setProfile] = useState({});
  const { moodle } = useContext(ContextApp);
  const [studentId, setStudentId] = useState(undefined);
  const [searchInput, setSearchInput] = useState("");
  const [students, setStudents] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [activeButtom, setActiveButton] = useState("history");

  const [loadingHistoric, setLoadingHistoric] = useState(false);
  const [loadingProfile, setLoadingProfile] = useState(false);

  const { setCrumbs } = useContext(ContextApp);

  useEffect(() => {
    let newCrumbs = [
      { name: "Home", path: "home", icon: homeIcon },
      { name: "Busca", path: "search", icon: searchPageIconColored },

    ];
    setCrumbs(newCrumbs);
  }, []);
  
  useEffect(() => {
    if (studentId !== undefined) {
      setLoadingHistoric(true);
      setHistoric([]);
      setStudents([]);
      setCertificates([]);
      StudentService.getHistoricByStudent(moodle, studentId).then((data) => {
        Promise.resolve(data);
        setHistoric(data.data);
        
        StudentService.getCertificatesByStudent(moodle, studentId).then((data2) => {
          Promise.resolve(data2);
          setCertificates(data2.data);
        });

        setLoadingHistoric(false);
      });
      
    }
  }, [moodle, studentId]);
  

  useEffect(() => {
    if (studentId !== undefined) {
      setLoadingProfile(true);
      setProfile([]);
      StudentService.getProfile(moodle, studentId).then((data) => {
        Promise.resolve(data);
        setProfile(data.data[0]);
        //console.log(data.data[0])
        setLoadingProfile(false);
      });
    }
  }, [moodle, studentId]);

  useEffect(() => {
    setHistoric([]);
    setCertificates([]);
    setStudentId(undefined);
    setStudents([]);
    if (searchInput.length > 3 && searchInput !== null)
      StudentService.searchByName(moodle, searchInput).then((response) => {
        Promise.resolve(response);
        setStudents(response.data);
      });
  }, [searchInput, moodle]);

  const onKeyUp = (event) => {
    event.preventDefault();
    setSearchInput(event.target.value);
  };

  const createTable = () => {
    return (
      <>
        <ButtonGroup className="ButtonGroup">
          <Button
            className={
              activeButtom === "history"
                ? "ButtonItemActive"
                : "ButtonItem"
            }
            onClick={() => setActiveButton("history")}
          >
            HISTÓRICO DESEMPENHO
          </Button>
          <Button
            className={
              activeButtom === "certificate"
                ? "ButtonItemActive"
                : "ButtonItem"
            }
            onClick={() => setActiveButton("certificate")}
          >
            CERTIFICADOS
          </Button>
        </ButtonGroup>
        <hr/>
          {activeButtom === "history" ?
            <Table className="tableData">
              <thead>
                <tr>
                  <th>Ciclo</th>
                  <th>Período</th>
                  <th>Acesso</th>
                  <th>Desempenho</th>
                </tr>
              </thead>
              <tbody>
                {historic.map((row, index) => {

                  return (
                    <tr key={index}>
                      <td>{row.periodo}</td>
                      <td>
                      {moment(row.data_inicio, "YYYY-MM-DD")
                          .format("DD/MM/YYYY")
                          .toString()}{" "}
                        -{" "}
                        {moment(row.data_fim, "YYYY-MM-DD")
                          .format("DD/MM/YYYY")
                          .toString()}
                      </td>
                      <td>{row.acesso}</td>
                      <td>
                        {row.desempenho}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          : activeButtom === "certificate" ?
            <div className="tableData container-certificates">
            {certificates.map((elm) => {
                return (
                  <a className="Certificate btn"
                    href={"https://sigu.unasus.ufma.br/certificacao/exibircertificado.php?codigoDocumento="+elm.hashSAD}
                    target="_blank">
                    {elm.nomeCurso}
                  </a>
                );
              })}
          </div>
          : null}
          
      </>
    );
  };

  return (
    <PageLayout pageIcon={searchPageIcon} pageTitle="Busca">
      <div className="SearchPage">
        <div className="studentSearch">
          <h6>
            Seja bem-vindo a Área de Busca, digite o nome do profissional
            para ver detalhes.
          </h6>
          <input
            type="text"
            placeholder="Buscar usuário"
            name="search"
            className="form-control searchInput"
            onKeyUp={onKeyUp}
          />
        </div>
        <div className="StudentContainer students">
          {searchInput.length > 0 &&
            historic.length === 0 &&
            students.map((elm) => {
              return (
                <Link 
                  className="Student btn" 
                  key={elm.id} 
                  to={{
                    search: `?search=${elm.id}`,
                  }}
                  onClick={() => setStudentId(elm.id)}
                >
                  {elm.firstname + " " + elm.lastname}
                    <img src={searchIcon} alt="imageSearch"/>
                </Link>
              );
            })}
        </div>
        {historic.length > 0 && Object.keys(profile).length > 0 ? (
          <>
            <div className="StudentDetails">
              <StudentProfile profile={profile} />{" "}
              <>
                <div className="TableData">{createTable()}</div>
              </>{" "}
            </div>
          </>
        ) : null}
        {loadingHistoric || loadingProfile ? <Loader /> : null}
        <br />
      </div>
    </PageLayout>
  );
};

export default SearchComponent;
