import axios from "axios";
import jwt_decode from "jwt-decode";

const baseURL = window._env_.REACT_APP_BACKEND_URL ;


const AuthService = {
  login: async function(username, password) {

    const instance = axios.create();

    return instance
      .post(baseURL + `/user/login`, {
        
          username: username,
          password: password
        ,
      })
      .then((response) => {
        Promise.resolve(response)
        if(response.data.status){
      
          let token = response.data.token 

          const user = jwt_decode(token)

          this.setUser({...response.data, name:user.name})
          
          return {...response.data, name:user.name};
        }else
          return {status: false}
      })
      .then((error)=>{
        return error
      })
  },

  setUser : async function(data){
    localStorage.setItem("user", JSON.stringify(data))
  },

  setName: async function(name){
    const user = JSON.parse(localStorage.getItem("user"))
    if (user !== null){
      user.name = name
      this.setUser(user)
    }
  },


  logout: async function(){
    localStorage.removeItem("user")
  },

  register: async function(username,email,password){

    const instance = axios.create();

    return instance.post(baseURL + "/signup", {
      username,
      email,
      password
    })
  },

  getCurrentUser: async function(){
    const user = JSON.parse(localStorage.getItem("user"))
    if (user !== null)
      return ({status: user.status, name: user.name})
    else
      return null
  },

  getCurrentUserId: async function(){
    const user = localStorage.getItem('user')

    if (user !== null){
        const data = JSON.parse(user)
        const token = jwt_decode(data.token)
        
        return token.id
    }else
      return false
  },

  isAuthenticated: async function(){
    const now = (Date.now() / 1000)

    const user = localStorage.getItem('user')

    if (user !== null){
        const data = JSON.parse(user)

        const token = jwt_decode(data.token)
        
        //verifica se a sessão ainda não foi expirada
        if (token.exp > now){ 
          return true
        }
        else{ //desconecta o usuário removendo o token do LocalStorage
          localStorage.clear()
          return false
        }
    }else
      return false
  },

  isAdmin: async function(){
    const user = localStorage.getItem('user')

    if (user !== null){
        const data = JSON.parse(user)
        const token = jwt_decode(data.token)
        //console.log(token.admin)
        if (token.admin === 1){ 
          return true
        }
        else{
          return false
        }
    }else
      return false
  },

};

export default AuthService;
