import axios from "axios";

const baseURL = window._env_.REACT_APP_BACKEND_URL ;

class UserService {

  getProfile(id) {
    const instance = axios.create();
    //console.log(id)
    return instance
      .get(baseURL + `/user/profile/${id}`)
      .then((response) => {
        return response.data;
      })
  }
  
  setProfile(data) {
    const instance = axios.create();
    return instance
      .put(baseURL + `/user/updateProfile`, data)
      .then((response) => {
        return response.data;
      })
  }

  setPassword(data) {
    const instance = axios.create();
    return instance
      .put(baseURL + `/user/updatePassword`, data)
      .then((response) => {
        return response.data;
      })
  }
}

export default new UserService();
