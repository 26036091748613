import React, { useState, useContext, useEffect } from "react";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ContextApp from "../contexts/ContextApp";
import {
  homeIcon,
  headerImgIcon,
  searchIconWhite,
  reportIcon,
  userIcon,
} from "../assets/images";
import PageLayout from "../Layouts/PageLayout";
import { AuthService } from "../services";

import "../styles/Home.css";


const HomePage = () => {
  const navigate = useNavigate();
  const { moodle } = useContext(ContextApp);
  const { setCrumbs } = useContext(ContextApp);
  const [admin, setAdmin] = useState(false);

  useEffect(async () => {
    let newCrumbs = [
      { name: "Home", path: "home", icon: homeIcon }
    ];
    setCrumbs(newCrumbs);

    const admin = await AuthService.isAdmin();
    setAdmin(admin)
    
  }, []);

  return (
    <PageLayout pageIcon={headerImgIcon} pageTitle="Home">
      <div className="LayoutBody">
        <div className="CardOptions">
          <Card className="btn"
              onClick={() => {
                navigate("/reports", { moodle: { moodle } });
              }}
            >
            <Card.Header>
              <img
                src={reportIcon}
                alt="imageSearch"
                style={{ width: "3rem"}}
              />
            </Card.Header>
            <Card.Body>
              <Card.Title style={{ fontWeight: "600" }}>Relatórios</Card.Title>
              <Card.Text>
                Nesta área, você poderá visualizar o relatório de desempenho dos 
                alunos em um ou mais períodos do Segundo Ciclo Formativo.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="btn"
              onClick={() => {
                navigate("/search", { moodle: { moodle } });
              }}
          >
            <Card.Header>
              <img
                src={searchIconWhite}
                alt="imageSearch"
                style={{ width: "3rem" }}
              />
            </Card.Header>
            <Card.Body>
              <Card.Title style={{ fontWeight: "600" }}>Busca</Card.Title>
              <Card.Text>
                Nesta área, será possível procurar por um aluno e visualizar
                dados referentes a sua situação dentro do programa do Segundo
                Ciclo do Mais Médicos.
                Os desempenhos retornados serão calculados em tempo real.
              </Card.Text>
            </Card.Body>
          </Card>
          {admin === true ? 
          <Card className="btn" onClick={() => {navigate("/users", { moodle: { moodle } }); }}>
            <Card.Header>
              <img
                src={userIcon}
                alt="imageUser"
                style={{ width: "3rem" }}
              />
            </Card.Header>
            <Card.Body>
              <Card.Title style={{ fontWeight: "600" }}>Usuários</Card.Title>
              <Card.Text>
                Nesta seção, você terá a capacidade de pesquisar e visualizar informações 
                relacionadas aos usuários registrados no sistema de Gerenciamento de Usuários. 
                Aqui, você poderá visualizar, desativar, editar e cadastrar informações de cada usuário.
              </Card.Text>
            </Card.Body>
          </Card>
          : null}
        </div>
      </div>
    </PageLayout>
  );
};

export default HomePage;
