import React, { useState, useRef, useContext, useEffect } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useNavigate } from "react-router-dom";
import ContextApp from "../contexts/ContextApp";

import { AuthService } from "../services";

const LoginPage = ({ ...props }) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [checkBtn, setcheckBtn] = useState([]);
  const [form, setForm] = useState([]);
  const navigate = useNavigate();
  const { setCrumbs } = useContext(ContextApp);
  const ref = useRef(null)

  const required = value => {
    if (!value) {
      return (
        <div className="alert alert-danger" role="alert">
          Este campo é obrigatório
        </div>
      );
    }
  };


  useEffect(async () => {
    const user = await AuthService.getCurrentUser()
    if (user)
        return navigate('/home', { replace: true }); // <-- redirect
    setCrumbs([]);
  }, []);
  

  const handleLogin = async function(e){
    e.preventDefault();

    form.validateAll();

    if (checkBtn.context._errors.length === 0) {
      await AuthService.login(username, password).then(
        (data) => {
          Promise.resolve(data)

          if(data.status) // equal true
          {
            //AuthService.setUser(data)
            navigate('/home', { replace: true }); // <-- redirect
          } 
          if(!data.status) // equal false
          {
            const error = "dados incorretos para login"

          
            const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

            setLoading(false);
            setMessage(resMessage);
          }            
        },
        (error) => {
          Promise.reject(error)
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="col-md-12">
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form
          onSubmit={handleLogin}
          ref={(c) => {
            setForm(c);
          }}
        >
          <div className="form-group">
            <label htmlFor="username">CPF</label>
            <Input
              ref={ref}
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">SENHA</label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <button
              className="btn btn-primary btn-block"
              disabled={loading}
            >
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>ENTRAR</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton
            style={{ display: "none" }}
            ref={(c) => {
              setcheckBtn(c);
            }}
          />
        </Form>
      </div>
    </div>
  );
};

export default LoginPage;