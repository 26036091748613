import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthService } from "../services";


export const ProtectedRoute = ({ children, admin }) => {
    const navigate = useNavigate();
    
    useEffect(async function(){
        const user = await AuthService.isAuthenticated()
        const userAdmin = await AuthService.isAdmin()

        if (!user || (admin === "true" && admin !== userAdmin)){
            return navigate('/', { replace: true }); // <-- redirect
        }
    })

    return children;
  };