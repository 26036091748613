import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ContextApp from "../contexts/ContextApp";
import {
  homeIcon,
  perfilImgIcon,
} from "../assets/images";
import PageLayout from "../Layouts/PageLayout";
import { AuthService, UserService } from "../services";

import "../styles/Profile.css";


const ProfilePage = () => {
    const navigate = useNavigate();

    const [userId, setUserId] = useState(0)
    const [profile, setProfile] = useState({});
    const [name, setName] = useState("")
    const [city, setCity] = useState("")
    const [country, setCountry] = useState("")
    const [password, setPassword] = useState("")

    const { setCrumbs } = useContext(ContextApp);

    useEffect(async () => {
        let newCrumbs = [
        { name: "Home", path: "home", icon: homeIcon },
        { name: "Perfil", path: "profile", icon: homeIcon }
        ];
        setCrumbs(newCrumbs);
    }, []);

    useEffect(async () => {
        const user = await AuthService.getCurrentUserId();
        setUserId(user)
        //console.log(user)

        UserService.getProfile(user).then((data) => {
            Promise.resolve(data);
            setProfile(data[0]);
            setName(data[0].name);
            setCity(data[0].city);
            setCountry(data[0].country);
            //console.log(data[0])
          });
        
    }, []);

    const checkEditProfile = function(){
        if (name === profile.name && city === profile.city && country === profile.country){
            return false
        }else{
            return true
        }
    }

    const checkEditPassword = function(){
        if (password === ""){
            return false
        }else{
            return true
        }
    }

    const saveProfile = function(e){
        e.preventDefault()
        let status = true
        const data1 = {
            id: userId,
            name,
            city,
            country
        }

        if (checkEditProfile()){
            UserService.setProfile(data1).then(async (data) => {
                Promise.resolve(data);
                //console.log(data)
                if (data && data.status){
                    await AuthService.setName(name);
                    window.location.reload();
                    alert("Perfil atualizado com sucesso!")
                }else{
                    alert("ERRO ao atualizar dados do perfil")
                    status = false
                }
            });
        }
        if (checkEditPassword()){
            const data2 = {
                id: userId,
                password
            }
            UserService.setPassword(data2).then((data) => {
                Promise.resolve(data);
                //console.log(data)
                if (data && data.status){
                    alert("Senha atualizada com sucesso!")
                }else{
                    alert("ERRO ao atualizar senha")
                    status = false
                }
            });
        }

        if (status === true)
            navigate('/home', { replace: true })
        else
            window.location.reload();
    }

    return (
        <PageLayout pageIcon={perfilImgIcon} pageTitle="Perfil">
            <div className="ProfilePage">
                <form onSubmit={(e) => saveProfile(e)}>
                    <div className="columns">
                        <h6>Nome</h6>
                        <input
                            className="border rounded"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    
                    <div className="columns">
                        <h6>Username</h6>
                        <input className="border rounded" disabled value={profile.username || ''}/>
                    </div>

                    <div className="columns">
                        <h6>E-mail</h6>
                        <input className="border rounded" disabled value={profile.email || ''}/>
                    </div>
                    
                    <div className="columns">
                        <h6>idade/Município</h6>
                        <input
                            className="border rounded"
                            type="text"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                        />
                    </div>
                    
                    <div className="columns">
                        <h6>País</h6>
                        <input
                            className="border rounded"
                            type="text"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                        />
                    </div>

                    <div className="columns">
                        <span>*Preencha este campo apenas caso queira alterar sua senha atual.</span>
                        <h6 className="text-new-password">Nova Senha</h6>
                        <input
                            className="border rounded"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <button className="btn btn-primary" type="submit" disabled={!(checkEditProfile() || checkEditPassword())}>
                        Salvar perfil
                    </button>
                </form>
                
            </div>
        </PageLayout>
    );
};

export default ProfilePage;
