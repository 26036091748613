import React from "react";
import { CircleSpinnerOverlay } from "react-spinner-overlay";

export default function Loader(props) {
    return (
        <CircleSpinnerOverlay
            overlayColor="rgb(0 41 68 / 18%)"
            size={80}
            color="#00C39D"
        />
    );
}