import React, { useContext } from "react";
import { Link } from "react-router-dom";
import ContextApp from "../contexts/ContextApp";
import styled from "styled-components";

const BreadCrumbContainer = styled.div`
display: flex !important;
flex-flow: row nowrap;
gap: 0.5rem;
margin: 0.5rem 0 0.5rem 0;
`;

const BreadCrumbItem = styled.div`
display: flex !important;
flex-flow: row nowrap;
justify-content: space-between;
gap: 0.5rem;
color: ${(props) => props.color || "#69c49b"};
font-size: 1rem;

img {
  width: 1.2rem;
  filter: brightness(100);
  color: #ffffff;
}
.link {
  color: ${(props) => props.color || "#69c49b"};
}
`;

const Breadcrumbs = () => {
  const { crumbs} = useContext(ContextApp);

  return (
    <BreadCrumbContainer>
      {crumbs.map(({ name, path, icon }, key) => {
        return crumbs.length === key +1 && key > 1 ? (
          <BreadCrumbItem key={key}>
            <span style={{ color: "white" }}> {">"} </span>
            <img src={icon} alt="icone do menu"/>
            <Link style={{ textDecoration: "none", color:"#69c49b" }} key={key} to={"/"+path}>
              {name}
            </Link>
          </BreadCrumbItem>
        ) : (
          <BreadCrumbItem key={key}>
            {key >= 1 && <span style={{ color: "white" }} > {">"} </span>}
            <img src={icon} alt="icone da menu sanduíche" />
            <Link style={{ textDecoration: "none", color: "white" }} key={key}  className="link" to={"/"+path} >
              {name}
            </Link>
          </BreadCrumbItem>
        );
      })}
    </BreadCrumbContainer>
  );
};
export default Breadcrumbs;
