import axios from "axios";

const baseURL = window._env_.REACT_APP_BACKEND_URL ;

class StudentService {
  
  getHistoricByStudent(moodle, student) {
    const instance = axios.create();

    return instance
      .get(baseURL + `/${moodle}/historico/${student}`)
      .then((response) => {
        return response.data;
      })
  }

  getCertificatesByStudent(moodle, student) {
    const instance = axios.create();

    return instance
      .get(baseURL + `/${moodle}/certificados/${student}`)
      .then((response) => {
        return response.data;
      })
  }

  getProfile(moodle, student) {
    const instance = axios.create();

    return instance
      .get(baseURL + `/${moodle}/profile/${student}`)
      .then((response) => {
        return response.data;
      })
  }  

  searchByName(moodle = "moodleB", search = "candenor") {
    const instance = axios.create();

    return instance
      .get(baseURL + `/${moodle}/search/${search}`)
      .then((response) => {
        return response.data;
      })
  }
}

export default new StudentService();
