import axios from "axios";

const baseURL = window._env_.REACT_APP_BACKEND_URL ;

class ReportService {
  getPeriodList(moodle) {
    const instance = axios.create();

    return instance.get(baseURL + `/${moodle}/relatorio/periodos`).then((response) => {
      return response.data;
    });
  }

  getPeriodDetailsByIds(moodle, idList) {
    const instance = axios.create();

    return instance
      .get(baseURL + `/${moodle}/relatorio/carregar`, {
        params: {
          periodos: idList
        },
      })
      .then((response) => {
        return response.data;
      });
  }
}

export default new ReportService();
