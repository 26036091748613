import React from "react"
import { Header } from "../components"
import { useLocation } from 'react-router-dom'


const Layout = ({ children }) => {
  const location = useLocation();
  const isLoginPage = location.pathname === "/";

  if (isLoginPage) {
    return (
      <main>{children}</main>
    )
  }

  return (
    <>
      <Header/>
      <main>{children}</main>
    </>
  )
}

export default Layout